import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
axios.defaults.withCredentials = true;

const initialState = {
	response: {},
	items: {},
	loading: false,
	currentItem: {},
	limit: 50
};

const publicationSlice = createSlice({
	name: 'publication',
	initialState,
	reducers: {
		setItemsData(state, action) {
			if(action.payload.hasOwnProperty('data')){
				state.items = action.payload.data.data;
				state.response = action.payload.data;
			}
			state.loading = false;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setItemData(state, action) {
			if(action.payload.hasOwnProperty('data')){
				if(action.payload.data.data && action.payload.data.data[0])
					state.currentItem = action.payload.data.data[0];
				
				state.response = action.payload.data;
			}
			state.loading = false;
		},
		updateItemByAcp(state, action) {
			let { data, currentItem } = action.payload;
			state.loading = false;
			if(data.hasOwnProperty('status')){
				state.currentItem = {
					...currentItem,
					legislation_acp: data.data.legislation
				};
			}
		}
	},
});

export const { setLoading,setItemsData,setItemData,updateItemByAcp } = publicationSlice.actions;

export const getItems =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { page, type, category, category2,dateParam,search,acp } = dataValues,
				url = `${API_URL}/dou?limit=`+initialState.limit;

			if(page !== undefined)
				url += "&page="+page;
			if(type !== undefined)
				url += "&type="+type;
			if(category !== undefined)
				url += "&category="+category;
			if(category2 !== undefined)
				url += "&category2="+category2;
			if(category2 !== undefined)
				url += "&category2="+category2;
			if(search !== undefined)
				url += "&search="+search;
			if(acp !== undefined && acp)
				url += "&acp="+acp;
			
			if(dateParam !== undefined){
				url += "&typeDate="+dateParam.type;
				url += "&data="+dateParam.value+(dateParam.type === "range" ? "|"+dateParam.value_end : "");
			}
			
			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let data = resp.data;
					dispatch(setItemsData(data));
				}
			}).catch((erro) => {
				dispatch(setItemsData({ data: {} }));
			})
		};

export const getItem =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { id } = dataValues,
				url = `${API_URL}/dou/`;
			
			if(id !== undefined){
				url += id;

				await axios.get(`${url}`).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(setItemData(data));
					}
				}).catch((erro) => {
					dispatch(setItemData({ data: {} }));
				})
			}else{
				dispatch(setItemData({ data: {} }));
			}
			
		};

export const setItem =
(dataValues) =>
	async (dispatch) => {
		dispatch(setLoading(true));
		var { id } = dataValues,
			url = `${API_URL}/dou/`;
		
		if(id !== undefined){
			url += id;

			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let data = resp.data;
					dispatch(setItemsData(data));
				}
			}).catch((erro) => {
				dispatch(setItemsData({ data: {} }));
			})
		}else{
			dispatch(setItemsData({ data: {} }));
		}
		
	};

export const addDomainsItem =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { domains, legislation } = dataValues,
				url = `${API_URL}/legislations`;

			if(domains !== undefined){
				await axios.post(`${url}`,{
					pt_id: legislation.legislation_acp.id,
					domains: domains.join(";")
				}).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
				})
			}else{
			}
		};

export const deleteDomainsItem =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { domain, legislation } = dataValues,
				url = `${API_URL}/legislations/domain/delete/${legislation.legislation_acp.id}?domains=${domain}`;
			
			//`${API_URL}/dou/legislations/domain/delete/${props.legislation.id}?domains=${id}`
			if(domain !== undefined){
				await axios.delete(`${url}`).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
					dispatch(updateItemByAcp({ data: {} }));
				})
			}else{
				dispatch(updateItemByAcp({ data: {} }));
			}
		};

export const addObsItem =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item, legislation } = dataValues;
			var url = `${API_URL}/obs/${legislation.legislation_acp.id}`;
			//`${window.$ApiParams.url}/obs/${item.id}`
			if(item !== undefined){
				await axios.post(`${url}`,item).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
				})
			}else{
			}
		};
export const deleteObsItem =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { _id_, legislation } = dataValues,
				url = `${API_URL}/obs/${legislation.legislation_acp.id}?obs_id=${_id_}`;
			
			if(_id_ !== undefined){
				await axios.delete(`${url}`).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
					dispatch(updateItemByAcp({ data: {} }));
				})
			}else{
				dispatch(updateItemByAcp({ data: {} }));
			}
		};

export const addAcpRevoke =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item, legislation } = dataValues,
				url = `${API_URL}/revoke/${legislation.legislation_acp.id}`;
			
			if(item !== undefined){
				await axios.post(`${url}`,item).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
				})
			}
		};

export const deleteAcpRevoke =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item, legislation } = dataValues,
				url = `${API_URL}/revoke/${legislation.legislation_acp.id}`;

			if(item !== undefined){
				let dataToSend = encodeURIComponent(item.revoked);
				await axios.delete(`${url}`+"?revoked="+dataToSend).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
					dispatch(updateItemByAcp({ data: {} }));
				})
			}else{
				dispatch(updateItemByAcp({ data: {} }));
			}
		};

export const addAcpChanges =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item, legislation } = dataValues,
				url = `${API_URL}/change/${legislation.legislation_acp.id}`;
			
			if(item !== undefined){
				await axios.post(`${url}`,item).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
				})
			}
		};

export const deleteAcpChanges =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item, legislation } = dataValues,
				url = `${API_URL}/change/${legislation.legislation_acp.id}`;

			if(item !== undefined){
				let dataToSend = encodeURIComponent(item.changed);
				await axios.delete(`${url}`+"?changed="+dataToSend).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(updateItemByAcp({ data: data,currentItem: legislation }));
					}
				}).catch((erro) => {
					dispatch(updateItemByAcp({ data: {} }));
				})
			}else{
				dispatch(updateItemByAcp({ data: {} }));
			}
		};


export const acpPublication =
(dataValues) =>
	async (dispatch) => {
		dispatch(setLoading(true));
		var { item } = dataValues,
			url = `${API_URL}/legislations/save`;
		
		if(item !== undefined){
			await axios.post(`${url}`,item).then((resp) => {
				if(resp.status){
					window.location.reload(false);
				}
			}).catch((erro) => {
			})
		}
	};

const publicationReducer = publicationSlice.reducer;

export default publicationReducer;

//2022-09-15