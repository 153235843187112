import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
//axios.defaults.headers.common['token'] = localStorage.getItem('mir:token');

const initialState = {
	status: 'idle',
	items: [],
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		notificationsLoading(state) {
			state.status = 'loading';
		},
		notificationsLoaded(state, action) {
			if(action.payload.hasOwnProperty('data')){
				let { data } = action.payload;
				state.items = data.data;
			}
			state.status = 'idle';
		},
	},
});

export const { notificationsLoading, notificationsLoaded } = notificationSlice.actions;

export const fetchNotifications = () => async (dispatch) => {
	dispatch(notificationsLoading());
	const response = await axios.get(`${API_URL}/notifications`);
	dispatch(notificationsLoaded(response.data));
};

export const readOne =
	(dataValues) =>
		async (dispatch) => {
			var { id } = dataValues,
				url = `${API_URL}/notifications/delete/`;
			
			//`${API_URL}/dou/legislations/domain/delete/${props.legislation.id}?domains=${id}`
			if(id !== undefined){
				await axios.delete(`${url}${id}`).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(fetchNotifications());
					}
				}).catch((erro) => {
					dispatch(fetchNotifications());
				})
			}else{
				dispatch(fetchNotifications());
			}
		};

const notificationReducer = notificationSlice.reducer;
export default notificationReducer;
