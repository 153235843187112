import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
axios.defaults.withCredentials = true;

const initialState = {
	response: {},
	items: {},
	loading: true
};

const domainSlice = createSlice({
	name: 'domain',
	initialState,
	reducers: {
		setItemsData(state, action) {
			if(action.payload.hasOwnProperty('data')){
				state.items = action.payload.data.data;
				state.response = action.payload.data;
			}
			state.loading = false;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
	},
});

export const { setLoading,setItemsData } = domainSlice.actions;

export const getItems =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { search, legislation  } = dataValues,
				url = `${API_URL}/entities?join=true&lgp_id=${legislation}`,
				searchParam  = "";
			
			if(search.length){
				searchParam = "&search="+search;
			}
			
			await axios.get(`${url}${searchParam}`).then((resp) => {
				if(resp.status){
					let data = resp.data;
					dispatch(setItemsData(data));
				}
			}).catch((erro) => {
				dispatch(setItemsData({ data: {} }));
			})
	};

const domainReducer = domainSlice.reducer;

export default domainReducer;

//2022-09-15