import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
import { format } from 'date-fns';
axios.defaults.withCredentials = true;

const initialState = {
	loading: false,
	statuses: {},
	plans: [],
	pageCount: 1,
	pageIndex: 0,
};

const planoAcao = createSlice({
	name: 'planoAcao',
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setItems(state, action) {

			if(action.payload.hasOwnProperty('data')){
				let { data,total,per_page,current_page } = action.payload.data;
				
				if(data != undefined){
					state.pageCount = Math.ceil(total/per_page);
					state.pageIndex = current_page;
					state.plans = data;
				}else{
					state.pageCount = initialState.pageCount;
					state.pageIndex = initialState.pageIndex;
					state.plans = initialState.plans;
				}
				
			}else{
				state.plans = [];
			}

			state.loading = false;
		},
		setStatuses(state, action) {
			
			if(action.payload.hasOwnProperty('data')){
				state.statuses = action.payload.data;
			}

			state.loading = false;
		},
		updateOneItemList(state, action) {
			state.plans = action.payload;
			state.loading = false;
		},
	},
});

export const { setLoading,setStatuses,setItems,updateOneItemList } = planoAcao.actions;

export const getPlans =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { ipl,pageIndex, term,sortBy } = dataValues,
				url = `${API_URL}/plans`,
				limit = 10;
			
			if(pageIndex == undefined){
				pageIndex = 1;
			}
			pageIndex = pageIndex + 1;

			var searchParam = "";
			if(term != undefined){
				searchParam = "&search="+term;
			}
			var sortByParam = "";
			if(sortBy != undefined && sortBy[0] != undefined && sortBy[0].hasOwnProperty("id")){
				sortByParam = "&sortby="+sortBy[0].id+":"+sortBy[0].desc;
			}
			var legislationParam = "";
			if(ipl != undefined){
				legislationParam = "&pt_id="+ipl;
			}
			
			await axios.get(`${url}?limit=${limit}&p=${pageIndex}${legislationParam}${searchParam}${sortByParam}`).then((resp) => {
				if(resp.status){
					let data = resp.data;
					dispatch(setItems(data));
				}
			}).catch((erro) => {
				dispatch(setStatuses({}));
			})
		};

export const getStatuses =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/statuses`;
			
			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let data = resp.data;
					dispatch(setStatuses(data));
				}
			}).catch((erro) => {
				dispatch(setStatuses({}));
			})
		};

export const updatePlan =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item,plans } = dataValues,
				url = `${API_URL}/plans`;
			
			if(item !== undefined){
				if(item.user_id_to){
					item.userto = item.user_id_to;
				}

				if(item.cost.slice(-3).search(",") === 0){
					item.cost = item.cost.replace(".","").replace(",",".");
				}
				if(item.data != undefined){
					item.data = format(item.data,"yyyy-MM-dd")
				}
				
				await axios.put(`${url}/${item.id}`,item).then((resp) => {
					if(resp.status){
						let {data} = resp.data;
						let newsList = [...plans];
						for(var x in newsList){
							if(newsList[x].id === data.id){
								newsList[x] = data;
								dispatch(updateOneItemList(newsList));
							}
						}
					}
				}).catch((erro) => {
					dispatch(setLoading(false));
				})
			}else{
				dispatch(setLoading(false));
			}
		};

export const addPlan =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item,ipl } = dataValues,
				url = `${API_URL}/plans`;
			
			if(item !== undefined && ipl != undefined){
				if(item.user_id_to){
					item.userto = item.user_id_to;
				}

				if(item.cost.slice(-3).search(",") === 0){
					item.cost = item.cost.replace(".","").replace(",",".");
				}
				if(item.data != undefined){
					item.data = format(item.data,"yyyy-MM-dd")
				}
				if(ipl){
					item.pt_id = ipl;
				}
				await axios.post(`${url}`,item).then((resp) => {
					if(resp.status){
						dispatch(getPlans({
							ipl: ipl,
							pageIndex: 0
						}));
					}
				}).catch((erro) => {
					dispatch(setLoading(false));
				})
			}else{
				dispatch(setLoading(false));
			}
		};

export const deletePlan =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { plan,ipl } = dataValues,
				url = `${API_URL}/plans/`;
			
			//`${API_URL}/dou/legislations/domain/delete/${props.legislation.id}?domains=${id}`
			if(plan !== undefined){
				await axios.delete(`${url}${plan.id}`).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(getPlans({
							ipl: ipl,
							pageIndex: 0
						}));
					}
				}).catch((erro) => {
					dispatch(getPlans({
						ipl: ipl,
						pageIndex: 0
					}));
				})
			}else{
				dispatch(getPlans({
					ipl: ipl,
					pageIndex: 0
				}));
			}
		};

const planoAcaoReducer = planoAcao.reducer;

export default planoAcaoReducer;

//2022-09-15