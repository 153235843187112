import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
axios.defaults.withCredentials = true;
//axios.defaults.headers.common['token'] = localStorage.getItem('mir:token');

const initialState = {
	entities: 0,
	users: 0,
	usersWithAccess: 0,
	ipls: 0,
	loading: false,
};

const dashboardSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setEntities(state, action) {
			if(action.payload.hasOwnProperty('data')){
				let { total } = action.payload.data;
				if(total !== undefined){
					state.entities = total;
				}else{
					state.entities = total;
				}
			}
			state.loading = false;
		},
		setUsers(state, action) {
			if(action.payload.hasOwnProperty('data')){
				let { total } = action.payload.data;
				if(total !== undefined){
					state.users = total;
				}else{
					state.users = total;
				}
			}
			state.loading = false;
		},
		setUsersAccess(state, action) {
			if(action.payload.hasOwnProperty('data')){
				let { total } = action.payload.data;
				if(total !== undefined){
					state.users = total;
				}else{
					state.users = total;
				}
			}
			state.loading = false;
		},
		setIpls(state, action) {
			
			if(action.payload.hasOwnProperty('data')){
				let { total } = action.payload.data;
				if(total !== undefined){
					state.ipls = total;
				}else{
					state.ipls = total;
				}
			}
			state.loading = false;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		}
	},
});

export const { setLoading,setEntities,setUsers,setUsersAccess,setIpls } = dashboardSlice.actions;

export const getIpls =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/legislations-info?action=ipls`;

			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let dataResponse = resp.data;
					dispatch(setIpls(dataResponse));
				}
			}).catch((erro) => {
				dispatch(setLoading(false));
			})
		};

export const getEntities =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/infos/entities?action=total`;

			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let dataResponse = resp.data;
					dispatch(setEntities(dataResponse));
				}
			}).catch((erro) => {
				dispatch(setLoading(false));
			})
		};

export const getUsers =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/infos/users?action=total`;

			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let dataResponse = resp.data;
					dispatch(setUsers(dataResponse));
				}
			}).catch((erro) => {
				dispatch(setLoading(false));
			})
		};

export const getUsersWithAccess =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/infos/users?action=totalautorizados`;

			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let dataResponse = resp.data;
					dispatch(setUsersAccess(dataResponse));
				}
			}).catch((erro) => {
				dispatch(setLoading(false));
			})
		};

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;

//2022-09-15