import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
axios.defaults.withCredentials = true;

const initialState = {
	response: {},
	loading: false,
	read: undefined,
	ipl: {},
	currentItem: {},
	statuses: {},
};

const publicationAcp = createSlice({
	name: 'publicationAcp',
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setItemData(state, action) {
			
			if(action.payload.hasOwnProperty('data')){
				if(action.payload.data.hasOwnProperty('legislation')){
					state.currentItem = action.payload.data.legislation;
				}
				if(action.payload.data.hasOwnProperty('read')){
					state.read = action.payload.data.read;
				}
				if(action.payload.data.hasOwnProperty('ipl')){
					state.ipl = action.payload.data.ipl;
				}
			}
			
			state.response = action.payload;
			state.loading = false;
		},
		setStatuses(state, action) {
			state.loading = false;
		},
	},
});

export const { setLoading,setItemData,setStatuses } = publicationAcp.actions;

export const getItem =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { id } = dataValues,
				url = `${API_URL}/legislations/read/`;
			
			if(id !== undefined){
				url += id;

				await axios.get(`${url}`).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(setItemData(data));
					}
				}).catch((erro) => {
					dispatch(setItemData({}));
				})
			}else{
				dispatch(setItemData({}));
			}
		};

export const readLegislation =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item } = dataValues,
				url = `${API_URL}/legislations/read`;
			
			if(item !== undefined){
				await axios.post(`${url}`,item).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(setItemData(data));
					}
				}).catch((erro) => {
					dispatch(setItemData({}));
				})
			}else{
				dispatch(setItemData({}));
			}
		};

export const impactLegislation =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { item } = dataValues,
				url = `${API_URL}/legislations/impact`;
			
			if(item !== undefined){
				await axios.post(`${url}`,item).then((resp) => {
					if(resp.status){
						let data = resp.data;
						dispatch(setItemData(data));
					}
				}).catch((erro) => {
					dispatch(setItemData({}));
				})
			}else{
				dispatch(setItemData({}));
			}
		};

export const getStatuses =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/statuses`;
			
			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let data = resp.data;
					dispatch(setItemData(data));
				}
			}).catch((erro) => {
				dispatch(setStatuses({}));
			})
		};

const publicationReducer = publicationAcp.reducer;

export default publicationReducer;

//2022-09-15