import { DEFAULT_PATHS } from 'config.js';
import { lazy } from 'react';

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const pages = {
	index: lazy(() => import('views/default/Dashboard')),
	publication: {
		index: lazy(() => import('views/default/Publications')),
		view: lazy(() => import('views/default/PublicationView')),
	},
	user: {
		index: lazy(() => import('views/admin/users/Users'))
	},
	plans: {
		index: lazy(() => import('views/client/plans/Plans'))
	}
  };

const routesAndMenuItems = {
  mainMenuItems: [
	{
		path: DEFAULT_PATHS.APP,
		exact: true,
		redirect: true,
		to: `${appRoot}/dashboard`,
	},
	{
		path: `${appRoot}/dashboard`,
		component: pages.index,
		label: 'Dashboard',
		icon: 'home',
		protected: true,
	},
	{
		path: `${appRoot}/publicacoes`,
		component: pages.publication.index,
		label: 'Publicações',
		icon: 'grid-2',
		protected: true,
		subs: [
			{
				path: '/:id',
				hideInMenu: true,
				component: pages.publication.view
			}
		]
	},
	{
		path: `${appRoot}/planos`,
		component: pages.plans.index,
		label: 'Planos de Ação',
		icon: 'note',
		protected: true,
		roles: [3,4]
	},
	{
		path: `${appRoot}/users`,
		component: pages.user.index,
		label: 'Usuários',
		icon: 'user',
		protected: true,
		roles: [1,3]
	}
	],
	sidebarItems: [],
};
export default routesAndMenuItems;
