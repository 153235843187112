import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkUser } from 'auth/authSlice';
import axios from 'axios';
axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem('mir:token');
	config.headers.token =  token;
	return config;
});


function AuthProvider({ children }){
	const { outSystem,loading } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	useEffect( () => {
		checkData()
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[dispatch]);

	const checkData = async () => {
		dispatch(checkUser());
	}
	if(outSystem){
		return <h1>Sistema fora de funcionamento...</h1>
	}
	
	if(!loading){
		return (
			<>
				{children}
			</>
		);
	}else{
		return (<></>)
	}
	
	
}

export default AuthProvider;