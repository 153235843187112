// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

// import theme reducers
import settingsReducer from 'settings/settingsSlice';
import layoutReducer from 'layout/layoutSlice';
import langReducer from 'lang/langSlice';
import authReducer from 'auth/authSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import publicationSlice from 'components/publications/publicationSlice';
import publicationAcpSlice from 'components/publications/publicationAcpSlice';
import domainSlice from 'components/domains/domainSlice';
import userSlice from 'components/users/userSlice';
import planoAcaoSlice from 'components/plano-acao/planoAcaoSlice';
import dashboardAdmin from 'components/dashboard/dashboardSlice';
import dashboardClient from 'components/dashboard/dashboardClientSlice';

// import persist key
import { REDUX_PERSIST_KEY } from 'config.js';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['menu', 'settings', 'lang'],
};

const combinedReducer = combineReducers({
	settings: settingsReducer,
	layout: layoutReducer,
	lang: langReducer,
	auth: authReducer,
	menu: menuReducer,
	notification: notificationReducer,
	scrollspy: scrollspyReducer,
	publication: publicationSlice,
	domain: domainSlice,
	user: userSlice,
	publicationAcp: publicationAcpSlice,
	planoAcao: planoAcaoSlice,
	dashboardAdmin: dashboardAdmin,
	dashboardClient: dashboardClient
});

const persistedReducer = persistReducer(
	persistConfig,(rootState, action) => {
		let state = rootState;
		if (action.type == "auth/setReset") {
			state = undefined;
		}
		return combinedReducer(state, action);
	}
);
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});
const persistedStore = persistStore(store);

export { store, persistedStore };
