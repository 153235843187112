import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
axios.defaults.withCredentials = true;

const initialState = {
	users: [],
	pageCount: 1,
	pageIndex: 0,
	loading: false,
  };

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setItemsData(state, action) {
			if(action.payload.hasOwnProperty('data')){
				let { data,total,per_page,current_page } = action.payload.data;
				if(data != undefined){
					state.pageCount = Math.ceil(total/per_page);
					state.pageIndex = current_page;
					state.users = data;
				}else{
					state.pageCount = initialState.pageCount;
					state.pageIndex = initialState.pageIndex;
					state.users = initialState.users;
				}
				
			}
			state.loading = false;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		updateOneItemList(state, action) {
			state.users = action.payload;
			state.loading = false;
		},
	},
});

export const { setLoading,setItemsData,updateOneItemList } = userSlice.actions;

export const getItems =
	(dataValues) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var { pageIndex, term,sortBy } = dataValues,
				url = `${API_URL}/users`,
				limit = 25;
			
			if(pageIndex == undefined){
				pageIndex = 1;
			}else{
				pageIndex = pageIndex + 1;
			}
			

			var searchParam = "";
			if(term != undefined){
				searchParam = "&search="+term;
			}
			var sortByParam = "";
			if(sortBy != undefined && sortBy[0] != undefined && sortBy[0].hasOwnProperty("id")){
				sortByParam = "&sortby="+sortBy[0].id+":"+sortBy[0].desc;
			}
			
			await axios.get(`${url}?limit=${limit}&page=${pageIndex}${searchParam}${sortByParam}`).then((resp) => {
				if(resp.status){
					let dataResponse = resp.data;
					dispatch(setItemsData(dataResponse));
				}
			}).catch((erro) => {
				dispatch(setItemsData({ data: [] }));
				dispatch(setLoading(false));
			})
		};

export const updateUser =
	({ item,users }) =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/users/access/`;
			const response = await axios.put(`${url}${item.id}`, item).catch((erro) => {
				dispatch(setLoading(false));
			});
			
			if(response){
				const { data,status } = response.data;
				if(status === true){
					let newsList = [...users];
					for(var x in newsList){
						if(newsList[x].id === data.id){
							newsList[x] = data;
							dispatch(updateOneItemList(newsList));
						}
					}
				}else{
					dispatch(setLoading(false));
				}
			}
			
	};

export const importUsers =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/importUsers`;
			const response = await axios.get(`${url}`).catch((erro) => {
				dispatch(setLoading(false));
			});
			
			if(response){
				const { data,status } = response.data;
				if(status === true){
					dispatch(getItems({ pageIndex: 0,term: "",sortBy: ""}));
				}else{
					dispatch(setLoading(false));
				}
			}
			
	};

const userReducer = userSlice.reducer;

export default userReducer;

//2022-09-15