import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config.js';
axios.defaults.withCredentials = true;

const initialState = {
	awares: [0,0],
	loading: false,
  };

const dashboardAdmin = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setAware(state, action) {
			
			if(action.payload.hasOwnProperty('data')){
				let { total,read } = action.payload.data;
				if(total !== undefined){
					state.awares = [read,total-read];
				}else{
					state.awares = [0,0];
				}
			}
			state.loading = false;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		}
	},
});

export const { setLoading,setAware } = dashboardAdmin.actions;

export const getAwares =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			var url = `${API_URL}/legislations-info?action=aware`;

			await axios.get(`${url}`).then((resp) => {
				if(resp.status){
					let dataResponse = resp.data;
					dispatch(setAware(dataResponse));
				}
			}).catch((erro) => {
				dispatch(setLoading(false));
			})
		};

const dashboardAdminReducer = dashboardAdmin.reducer;

export default dashboardAdminReducer;

//2022-09-15