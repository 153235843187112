import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL,AUTH_TOKEN } from 'config.js';
axios.defaults.withCredentials = true;
//axios.defaults.headers.common['token'] = localStorage.getItem('mir:token');

const initialState = {
	isLogin: false,
	currentUser: {},
	outSystem: false,
	loading: true,
	token: undefined
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setCurrentUser(state, action) {

			state.currentUser = action.payload.data.user;
			if(action.payload.data.token !=undefined){
				localStorage.setItem("mir:token", action.payload.data.token);
			}
			
			state.isLogin = true;
			state.loading = false;

		},
		setOutSytem(state, action) {
			state.outSystem = action.payload;
			state.loading = false;
		},
		setLoading(state, action) {
			state.loading = action.payload;
		},
		setLogin(state, action) {
			state.isLogin = action.payload;
		},
		setNullUser(state) {
			state = initialState;
			localStorage.removeItem('mir:token')
		},
		setReset(state,action){
			//use to clear data
		}
	},
});

export const { setCurrentUser,setOutSytem,setLoading,setLogin,setNullUser,setReset } = authSlice.actions;

export const loginUser =
	(dataValues) =>
		async (dispatch) => {
			const response = await axios.post(`${API_URL}/login`, { login: dataValues.email,password: dataValues.password });
			const data = response.data;
			dispatch(setCurrentUser({ data }));
		};

export const checkUser =
	(dataValues) =>
		async (dispatch) => {
			await axios.get(`${API_URL}/checktoken`).then((resp) => {
				dispatch(setLogin(false));
				if(resp.status){
					let data = resp.data;
					dispatch(setCurrentUser({ data }));
				}
			}).catch((erro) => {
				dispatch(setLoading(false));
				if(erro.response && erro.response.status !== 401){
					dispatch(setOutSytem(true));
				}else{
					dispatch(setLoading(false));
					dispatch(setLogin(false));
				}
				
			})
		};

export const logout =
	() =>
		async (dispatch) => {
			dispatch(setLoading(true));
			await axios.get(`${API_URL}/logout`).then((resp) => {
				let data = resp.data
				if(data.status){
					dispatch(setLogin(false));
					dispatch(setNullUser());
					dispatch(setReset());
					dispatch(setLoading(false));
				}

			}).catch((erro) => {
				if(erro.response && erro.response.status !== 401){
					dispatch(setOutSytem(true));
				}else{
					dispatch(setLoading(false));
					dispatch(setLogin(false));
				}
				
			})
			//dispatch(receiveService({ contacts, pageCount, loading: false, pageIndex }));
		};

const authReducer = authSlice.reducer;

export default authReducer;
